<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style lang="less">
body,
html,
#app {
    width: 100%;
    height: 100%;
}
.ivu-btn-text:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}
</style>
