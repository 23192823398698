
<style lang="less">
.layout {
    width: 100%;
    height: 100%;
    .left_container {
        display: flex;
        flex-direction: column;
        background-color: #171a1c;
        .title_container {
            height: 52px;
            width: 100%;
            line-height: 52px;
            background-color: #191919;
            text-align: center;
            // display: flex;
            justify-content: start;
            align-items: center;
            .title_logo {
                width: 100px;
                height: 24px;
                vertical-align: middle;
                margin-left: 20px;
            }
            .separat {
                width: 1px;
                height: 14px;
                // background: #FFFFFF;
                display: inline-block;
                vertical-align: middle;
                border-left: 1px solid #ffffff;
                margin-left: 8px;
                margin-right: 10px;
            }
            .title_text {
                font-family: PingFangSC-Light;
                font-weight: 300;
                font-size: 18px;
                color: #ffffff;
                display: inline-block;
                vertical-align: middle;
                margin-right: 18px;
            }
            .title_fold_btn {
                width: 16px;
                height: 16px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
        .sider_container {
            .ivu-menu-vertical.ivu-menu-light:after {
                background-color: #171a1c;
            }
            .ivu-menu-item-active:not(.ivu-menu-submenu):after {
                background-color: transparent;
            }
            .ivu-menu-item-active:not(.ivu-menu-submenu) {
                background-color: #4f6ef2;
                color: #ffffff;
            }
            .ivu-menu-item {
                background-color: #171a1c;
                color: #b8bbbf;
            }
            .ivu-menu-item-folded {
                //折叠的
                padding: 14px 15px;
                height: 50px;
                span {
                    width: 0px;
                    overflow: hidden;
                    display: inline-block;
                    transition: width 0.2s ease;
                }
                i {
                    // transform: translateX(5px);
                    // transition: transform .2s ease .2s;
                    vertical-align: middle;
                    // height: 50px;
                    // font-size: 22px;
                }
            }
            .ivu-menu-item-unfolded {
                //非折叠的
                padding: 15px 16px;
                // font-size: 16px;
                height: 50px;
                span {
                    display: inline-block;
                    overflow: hidden;
                    width: 120px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: bottom;
                    transition: width 0.2s ease 0.2s;
                }
                i {
                    // transform: translateX(0px);
                    // transition: transform .2s ease;
                    vertical-align: middle;
                }
            }
        }
    }
    .header_contaier {
        z-index: 200;
        height: 52px;
        width: 100%;
        padding: 0px;
        box-shadow: 0 2px 6px 0 rgba(112, 112, 112, 0.1);
        background: #ffffff;
        line-height: 52px;
        padding-right: 18px;
        overflow: hidden;
        .header_left_content {
            float: left;
            padding-left: 20px;
            font-size: 16px;
        }
        .header_right_content {
            float: right;
            vertical-align: middle;
            .message_container {
                vertical-align: middle;
                cursor: pointer;
            }
            .user_container {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #808080;
                display: inline-block;
                margin-left: 12px;
                vertical-align: middle;
                .user_dropdown_btn {
                    color: #808080;
                    cursor: pointer;
                }
            }
        }
    }
    .content_contaier {
        z-index: 100;
        box-shadow: 0 2px 6px 0 rgba(112, 112, 112, 0.1);
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
        padding: 20px;
        .content_contaier_all {
            width: 100%;
            height: 100%;
        }
    }
    .layout-logo {
        display: inline-block;
        vertical-align: middle;
        width: 169px;
        height: 40px;
        // background-image: url("../../static/logo.svg");
        cursor: pointer;
    }
}
</style>
<template>
    <div class="layout">
        <Layout style="height: 100%">
            <Sider
                v-model="isShowMenu"
                class="sider_container"
                hide-trigger
                :style="{ background: '#fff' }"
                :width="'260'"
                :collapsed-width="45"
                collapsible
            >
                <Menu width="auto" :active-name="activeName" @on-select="switchMenu">
                    <div v-for="item in routerList" :key="item.name">
                        <Submenu
                            :name="item.name"
                            v-if="item.children && item.children.length > 0"
                        >
                            <template #title>
                                {{ item.meta.title }}
                            </template>
                            <MenuItem
                                :name="itemChild.name"
                                v-for="itemChild in item.children"
                                :key="itemChild.name"
                                :to="itemChild.path"
                                :class="
                                    isShowMenu
                                        ? 'ivu-menu-item-folded'
                                        : 'ivu-menu-item-unfolded'
                                "
                                >{{ itemChild.meta.title }}</MenuItem
                            >
                        </Submenu>
                        <MenuItem
                            v-else
                            :name="item.name"
                            :to="item.path"
                            :class="
                                isShowMenu
                                    ? 'ivu-menu-item-folded'
                                    : 'ivu-menu-item-unfolded'
                            "
                        >
                            <i
                                :class="item.meta.icon ? item.meta.icon : ''"
                                style="font-size: 14px"
                            ></i>
                            <span>{{ item.meta.title }}</span>
                        </MenuItem>
                    </div>
                </Menu>
            </Sider>
            <Layout>
                <Header class="header_contaier">
                    <div class="header_left_content">
                        <i
                            v-if="currentRouterMeta.icon"
                            :class="
                                currentRouterMeta.icon
                                    ? currentRouterMeta.icon
                                    : ''
                            "
                            style="font-size: 14px"
                        ></i>
                        {{ currentRouterMeta.title }}
                    </div>
                    <div class="header_right_content">
                        <Icon
                            class="message_container"
                            type="ios-notifications"
                        />
                        <div class="user_container">
                            <span>{{ name }}</span>
                            <Dropdown
                                style="display: inline-block"
                                placement="bottom-end"
                            >
                                <a href="javascript:void(0)">
                                    <Icon
                                        class="user_dropdown_btn"
                                        type="md-arrow-dropdown"
                                    />
                                </a>
                                <template #list>
                                    <Dropdown-menu
                                        class="home-login-dropdown"
                                        style="padding: 10px"
                                    >
                                        <ButtonGroup vertical>
                                            <!-- <Button type="text" long @click="showPersonSetting">个人设置</Button> -->
                                            <Button
                                                type="text"
                                                long
                                                @click="logout"
                                                >注销</Button
                                            >
                                        </ButtonGroup>
                                    </Dropdown-menu>
                                </template>
                            </Dropdown>
                        </div>
                    </div>
                </Header>
                <Content
                    :style="{
                        height: '100%',
                        background: '#f3f5f9',
                    }"
                    class="content_contaier"
                >
                    <div class="content_contaier_all"><router-view /></div>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>

<script>
export default {
    data() {
        return {
            routerList: [],
            activeName: "",
            name: "",
            currentRouterMeta: {},
            isShowMenu:false
        };
    },
    mounted() {
        this.getRouterList();
        this.getCurrentRouter();
        this.name = this.$cookies.get("name");
    },

    methods: {
        logout: function () {
            this.$http
                .post("/api/logout")
                .then((data) => {
                    if (data.data) {
                        this.$cookies.remove("x-auth-token");
                        this.$router.push({ path: "/login" });
                    }
                })
                .catch((err) => {
                    this.$Message.error("注销失败");
                });
        },
        getRouterList() {
            var route = this.$router;
            var routeList = route.options.routes;
            if (routeList && routeList[0].children) {
                this.routerList = routeList[0].children;
            }
        },
        getCurrentRouter() {
            if (this.$route.name) {
                this.activeName = this.$route.name;
                console.log(this.$route);
                this.currentRouterMeta = this.$route.meta;
            }
        },
        switchMenu(e){
            for(var i=0;i<this.routerList.length;i++){
                if(this.routerList[i].children){
                    for(var j=0;j<this.routerList[i].children.length;j++){
                        if(this.routerList[i].children[j].name == e){
                            this.activeName = this.routerList[i].children[j].name;
                            this.currentRouterMeta = this.routerList[i].children[j].meta;
                            return
                        }
                    }
                }else{
                    if(this.routerList[i].name == e){
                        this.activeName = this.routerList[i].name;
                        this.currentRouterMeta = this.routerList[i].meta;
                        return
                    }
                }
            }
        },
        toHome() {
            this.$router.push({ path: "/" });
        },
        initRouterMenu() {},
    },
};
</script>
