import moment from 'moment';

function internalFormatDateTime(value, fmt) {
    //现在很多.0不知道哪里来的，先特殊处理一下
    if(value.constructor==String && value.substring(value.length -2, value.length) == ".0"){
            value = value.substring(0, value.length - 2).replace(/\-/g, "/");
    }

    var reg = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\s+(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/;
    //匹配"2014-01-01 12:00:00"
    if(value.constructor==String && reg.test(value)){
        value = value.replace(/\-/g, "/");
    }

    var date = new Date(value);
    var o = {
        "M+": date.getMonth() + 1, //月份   
        "d+": date.getDate(), //日   
        "h+": date.getHours(), //小时   
        "m+": date.getMinutes(), //分   
        "s+": date.getSeconds(), //秒   
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度   
        "S": date.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export function formatYD(val) {
    if (!val) {
        return;
    }
    return internalFormatDateTime(val, 'yyyy-MM');
}

export function formatDateTime(val,fmt){
    if (!val) {
        return;
    }
    return internalFormatDateTime(val, fmt);
}

export function formatDate(val) {
    if (!val) {
        return;
    }
    return internalFormatDateTime(val, 'yyyy-MM-dd');
};
export function formatDateToChineseChar (val) {
    if (!val) {
        return;
    }
    return internalFormatDateTime(val, 'yyyy年MM月dd日');
};
export function formatTime(val) {
    if (!val) {
        return;
    }
    return internalFormatDateTime(val, 'yyyy-MM-dd hh:mm:ss');
};
export  function formatDayTime(val){
    if (!val) {
        return;
    }
    return internalFormatDateTime(val, 'hh:mm:ss');
};
export  function formatYearMonth(val) {
    if (!val) {
        return;
    }
    return internalFormatDateTime(val, 'yyyy-MM');
};

export function formatLastYearDate (val) {
    if (val.length!=2) {
        return;
    }
    var startTime = new Date(val[0]);
    var endTime = new Date(val[1]);
    val[0] = moment(startTime).subtract(1, "years").format("YYYY-MM-DD");
    val[1] = moment(endTime).subtract(1, "years").format("YYYY-MM-DD");
    return val;
}

export function formatLastMonthDate (val) {
    if (val.length!=2) {
        return;
    }
    var startTime = new Date(val[0]);
    var endTime = new Date(val[1]);
    var startMoment = moment(startTime);
    var endMoment = moment(endTime);
    var count = endMoment.diff(startMoment, 'months')+1;
    val[0] = moment(startTime).subtract(count, "months").format("YYYY-MM-DD");
    val[1] = moment(endTime).subtract(count, "months").format("YYYY-MM-DD");
    return val;
}


export function formatCurrency (num,isInteger) {
    if (!num) {
        if (isInteger) {
            return "0";
        }
        return '0.00';
    }
    num = num.toString().replace(/\$|\,/g, '');
    if (isNaN(num))
        num = "0.00";
    var sign = (num == (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    var cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10)
        cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
        num = num.substring(0, num.length - (4 * i + 3)) + ',' +
            num.substring(num.length - (4 * i + 3));
    if (isInteger) {
        return (((sign) ? '' : '-') + num);
    }
    return (((sign) ? '' : '-') + num + '.' + cents);
}

export function formatNumber(currency){
    if(currency){
        return currency.toString().split(",").join("");
    }
    return currency;
}

export function formatFloat2Decimal(value) {
    if(!value) {
        return '0.00';
    }
    var value=Math.round(parseFloat(value)*100)/100;
    var xsd=value.toString().split(".");
    if(xsd.length==1){
        value=value.toString()+".00";
        return value;
    }
    if(xsd.length>1){
        if(xsd[1].length<2){
            value=value.toString()+"0";
        }
        return value.toString();
    }
}


export default {
    formatYD,
    formatDateTime,
    formatDate,
    formatDateToChineseChar,
    formatTime,
    formatDayTime,
    formatYearMonth,
    formatCurrency,
    formatNumber,
    formatFloat2Decimal,
    formatLastYearDate,
    formatLastMonthDate
}