<style lang="less" scoped>
.container {
    height: 100%;
    background-image: radial-gradient(#ecba00, #f3d66b 60%);
}

.login-wrapper {
    background-color: #fff;
    width: 500px;
    height: 588px;
    border-radius: 15px;
    padding: 0 50px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login-header {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    line-height: 200px;
    color: #514310;
}

.input-item {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    border: 0;
    padding: 10px;
    border-bottom: 1px solid rgb(128, 125, 125);
    font-size: 15px;
    outline: none;
}

.input-item:placeholder {
    text-transform: uppercase;
}

.btn {
    text-align: center;
    padding: 10px;
    width: 100%;
    margin-top: 40px;
    background-image: radial-gradient(#ecba00, #f3d66b 60%);
    color: #fff;
    cursor: pointer;
}

.btn-code {
    text-align: center;
    padding: 5px;
    width: 80px;
    background-image: radial-gradient(#ecba00, #f3d66b 60%);
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    position: absolute;
    right: 0px;
    top: 0;
    cursor: pointer;
}

.msg {
    text-align: center;
    line-height: 88px;
}

a {
    text-decoration-line: none;
    color: #abc1ee;
}
</style>
<style lang="less">
.ivu-login-captcha {
    .ivu-btn-large {
        font-size: 14px !important;
    }
}
.login-wrapper {
    .ivu-btn-primary {
        background-image: radial-gradient(#ecba00, #f3d66b 60%);
        border: none;
    }
}
</style>
<template>
    <div class="container">
        <div class="login-wrapper">
            <div class="login-header">欢迎登录</div>
            <div class="form-wrapper">
                <Login @on-submit="handleSubmit">
                    <UserName name="loginUsername" />
                    <Password name="loginPassword" />
                    <!-- <div class="demo-auto-login">
                        <Checkbox v-model="autoLogin" size="large"
                            >自动登录</Checkbox
                        >
                    </div> -->
                    <Submit />
                </Login>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return { autoLogin: true };
    },
    created() {
        this.getLoginStatus();
    },
    mounted() {},

    methods: {
        getLoginStatus() {
            this.$util.getLoginStatus().then((data) => {
                this.$router.push("/");
            });
        },
        doLogin(param) {
            this.$http
                .post("/api/api/login", param, {
                    headers: {
                        contentType: "application/x-www-form-urlencoded",
                    },
                })
                .then((data) => {
                    this.$cookies.set("x-auth-token", data.data.token);
                    this.$cookies.set("name", data.data.name);
                    this.$cookies.set("username", data.data.username);
                    this.$router.push("/");
                })
                .catch((err) => {
                    this.$Message.error("登录失败:" + err.response.data);
                });
        },
        handleSubmit(valid, { loginUsername, loginPassword }) {
            if (valid) {
                let param = new URLSearchParams();
                param.append("username", loginUsername);
                param.append("password", loginPassword);
                this.doLogin(param);
            }
        },
    },
};
</script>
