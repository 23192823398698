import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/index.vue'
import Login from '../views/login.vue'

// 功能注册
const functionList = [
  {
    meta: {
      title: "服务管理",
      icon: "ios-paper",
    },
    path: '/serviceManage',
    name: 'serviceManage',
    component: () => import('../views/function-service-manage'),

  },
  {
    meta: {
      title: "订单管理",
      icon: "icon iconfont icon-iot-product",
    },
    path: '/orderManage',
    name: 'orderManage',
    component: () => import('../views/function-order-manage'),

  },
  {
    meta: {
      title: "医院管理",
      icon: "icon iconfont icon-iot-product",
    },
    path: '/hospitalManage',
    name: 'hospitalManage',
    component: () => import('../views/function-hospital-setting'),

  },
  {
    meta: {
      title: "陪诊人申请单",
      icon: "icon iconfont icon-iot-product",
    },
    path: '/companionApply',
    name: 'companionApply',
    component: () => import('../views/function-companion-apply'),

  },
  {
    meta: {
      title: "陪诊人管理",
      icon: "icon iconfont icon-iot-product",
    },
    path: '/companionManage',
    name: 'companionManage',
    component: () => import('../views/function-companion-manage'),

  },
  {
    meta: {
      title: "会员管理",
      icon: "icon iconfont icon-iot-product",
    },
    path: '/memberManage',
    name: 'memberManage',
    component: () => import('../views/function-member-manage'),

  },
  {
    meta: {
      title: "首页图管理",
      icon: "icon iconfont icon-iot-product",
    },
    path: '/homeBanner',
    name: 'homeBanner',
    component: () => import('../views/function-home-banner'),

  },
  {
    meta: {
      title: "系统管理",
      icon: "icon iconfont icon-iot-product",
    },
    path: '/',
    name: 'test',
    children: [
      {
        meta: {
          title: "用户管理",
          icon: "icon iconfont icon-iot-product",
        },
        path: '/userManage',
        name: 'userManage',
        component: () => import('../views/function-user-manage'),

      },
      {
        meta: {
          title: "医院标签管理",
          icon: "icon iconfont icon-iot-product",
        },
        path: '/hospitalLabel',
        name: 'hospitalLabel',
        component: () => import('../views/function-hospital-label'),

      },
      {
        meta: {
          title: "陪诊人关系管理",
          icon: "icon iconfont icon-iot-product",
        },
        path: '/companionRelation',
        name: 'companionRelation',
        component: () => import('../views/function-companion-relation'),

      },
      {
        meta: {
          title: "陪诊人职业管理",
          icon: "icon iconfont icon-iot-product",
        },
        path: '/companionOccupation',
        name: 'companionOccupation',
        component: () => import('../views/function-companion-occupation'),

      },
      {
        meta: {
          title: "陪诊人工作经验",
          icon: "icon iconfont icon-iot-product",
        },
        path: '/workExperience',
        name: 'workExperience',
        component: () => import('../views/function-work-experience'),

      }
    ]
    // component: () => import('../views/test.vue'),
  },
];

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
    children: functionList
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
