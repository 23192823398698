import FormatUtil from './format-util';
import UUID_2fa28a48_e570_4582_a9ce_339b93a801bc from './uuid.js';
import _ from 'lodash'; 
let util = {};
util = _.assign(util, FormatUtil);
util.getLoginStatus = () => {
    return new Promise((res,rej)=>{
        $http.get("/api/login/status").then(data=>{
            res(data);
        }).catch(err=>{
            rej(err);
        })
    })
}
util.commonQuery = (param) => {
    return new Promise((res,rej)=>{
        $http.post("/api/common/queryAll",param).then(data=>{
            res(data);
        }).catch(err=>{
            rej(err);
        })
    })
}

util.commonLoad = (modelName,id) => {
    return new Promise((res,rej)=>{
        $http.get("/api/common/load/"+modelName+'/'+id).then(data=>{
            res(data);
        }).catch(err=>{
            rej(err);
        })
    })
}

util.commonDelete = (modelName,id) => {
    return new Promise((res,rej)=>{
        $http.delete("/api/common/delete/"+modelName+'/'+id).then(data=>{
            res(data);
        }).catch(err=>{
            rej(err);
        })
    })
}

util.commonDiscard = (modelName,id) => {
    return new Promise((res,rej)=>{
        $http.get("/api/common/load/"+modelName+'/'+id).then(data=>{
            var param = data.data
            param.discard = true
            $http.put("/api/common/save/"+modelName,param).then(data1=>{
                res(data1);
            })
        }).catch(err=>{
            rej(err);
        })
    })
}


util.commonSave = (modelName,param) => {
    return new Promise((res,rej)=>{
        $http.post("/api/common/save/"+modelName,param).then(data=>{
            res(data);
        }).catch(err=>{
            rej(err);
        })
    })
}

util.commonEdit = (modelName,param) => {
    return new Promise((res,rej)=>{
        $http.put("/api/common/save/"+modelName,param).then(data=>{
            res(data);
        }).catch(err=>{
            rej(err);
        })
    })
}

util.commonUploadFile = (param) => {
    const headers = {
        "content-type":'multipart/form-data;boundary=XXX',
    };
    return new Promise((res,rej)=>{
        $http.post("/api/basis/attachments/file",param,{headers}).then(data=>{
            res(data);
        }).catch(err=>{
            rej(err);
        })
    })
}

util.newId = function () {
    return UUID_2fa28a48_e570_4582_a9ce_339b93a801bc
        .create()
        .toString();
}
export default util;
