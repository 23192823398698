import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// iview
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'

import VueCookies from 'vue-cookies'
import axios from "axios";
import util from "./libs/util";

axios.interceptors.request.use(config => {
    let token = VueCookies.get("x-auth-token");
    if (token) { // 不为空的话就设置进headers
        config.headers["x-auth-token"] = token
    }
    return config;
}, err => {
    return Promise.reject(err)
})

axios.interceptors.response.use(
    res => {
        console.log(res);
        return Promise.resolve(res);
    },
    err => {
        if (err.response.status == 401 && err.config.url != '/api/api/login') {
            router.push('/login');
            return Promise.reject(err);
        } else {
            return Promise.reject(err);
        }
    }
)

router.beforeEach((to, from, next) => {
    if (to.name === 'login' && from.path != '/' && from.path != '/findpwd'){
        window.location.reload();
        return;
    }
    if (!(to.name === 'login' || to.name === 'findpwd')) {
        util.getLoginStatus().then(() => {
            next();
        }).catch(err=>{
            router.push('/login');
        })
    } else {
        next();
    }
});

const app = createApp(App)
app.config.globalProperties.$util = util;
app.config.globalProperties.$http = axios;
window.$http = axios;
window.$util = util;

// 懒得找别的地方设置图片的url 就在这设置吧
app.config.globalProperties.$preViewFileUrl = "https://www.youbanpeizhen.cn/api";
// app.config.globalProperties.$preViewFileUrl = "http://localhost:18092";
app.use(VueCookies);
app.use(ViewUIPlus);
app.use(store).use(router).mount('#app')
